import {
  FETCH_SEAT_LAYOUT_LOADING,
  FETCH_SEAT_LAYOUT_SUCCESS,
  FETCH_SEAT_LAYOUT_ERROR,
  FETCH_SEAT_TYPE_LOADING,
  FETCH_SEAT_TYPE_SUCCESS,
  FETCH_SEAT_TYPE_ERROR,
  FETCH_SHOW_DETAILS_SUCCESS,
  SET_SEAT_SELECTED,
  SET_RESERVATION_ID,
  SET_SEAT_SELECT_ERROR,
  SET_TRANSACTION_ID,
  SET_TRANSACTION_ERROR,
  CLEAR_BOOKING_STATE,
  SET_TICKET_PRICE,
  RESET_RESERVATION_ID,
  SET_GUEST_ID,
  START_TIMER,
  STOP_TIMER,
  SET_BOOKING_FEE_DATA,
  SET_ADDITIONAL_DATA,
  CLEAR_ERRORS,
  CLEAR_FOOD_AND_BEVERAGES_STATE,
  CLEAR_SELECTED_SEATS,
  SET_SEATS_STRING,
  SET_SESSION_ID,
  SELECT_FNB_ITEM,
  SET_BOOKING_ID,
  PICKUP_TICKET_LOADING,
  PICKUP_TICKET_SUCCESS,
  PICKUP_TICKET_CONTENT_SUCCESS,
  PICKUP_TICKET_ERROR,
  RESET_PICKUP_TICKET_ERROR,
  CLEAR_STORE,
  ON_SET_COMPLETE_RATE_CARD,
  ON_SET_COMPLETE_NEW_TOTAL_AMOUNT,
  SET_FT_ID,
  CLEAR_FNB_STATE,
  SET_PAYMENT_MODES,
  SET_BOOKING_DETAILS,
  SET_BOOKING_CODE,
  CLEAR_SELECTED_FNB_ITEMS,
  SET_FNB_BOOKING_DETAILS,
  FETCH_VOUCHERS,
  FETCH_VOUCHERS_SUCCESS,
  FETCH_VOUCHERS_ERROR,
  VERIFY_VOUCHER,
  VERIFY_VOUCHER_SUCCESS,
  VERIFY_VOUCHER_ERROR,
  UPDATE_SEAT_LAYOUT,
  FETCH_RESERVATION_DETAILS_V2,
  FETCH_RESERVATION_DETAILS_V2_SUCCESS,
  FETCH_RESERVATION_DETAILS_V2_ERROR,
  SET_CARD_INFO,
  CLEAR_CARD_INFO
} from './bookingTypes';
import { SET_CHECKOUT_TIMER } from '../global/globalTypes';
import { groupBy } from 'lodash';
import _ from 'lodash';
import BookingService from '@apiService/BookingService';
import MovieService from '@apiService/MovieService';
import FnbService from '@apiService/FnbService.js';
import { toast } from 'react-toastify';

import { electronSocket } from '@src/index.js';
import { fetchReservationDetails } from '../movies/moviesActions';
import { createQueryString } from '../../ApiServices/ApiHelper';
import benefitLogo from '@assets/imgs/benefit_pg_logo.png';
import masterCardLogo from '@assets/imgs/mastercard_pg_logo.png';
import masterCardStandardCharteredLogo from '@assets/imgs/scLogo.png';

var timerInterval = null;
const fetchSeatLayoutLoading = () => {
  return {
    type: FETCH_SEAT_LAYOUT_LOADING,
  };
};

const fetchSeatLayoutSuccess = (data) => {
  return {
    type: FETCH_SEAT_LAYOUT_SUCCESS,
    payload: data,
  };
};

const fetchSeatLayoutError = (err) => {
  return {
    type: FETCH_SEAT_LAYOUT_ERROR,
    payload: err,
  };
};

const fetchShowDetailsSuccess = (data) => {
  return {
    type: FETCH_SHOW_DETAILS_SUCCESS,
    payload: data,
  };
};

const setSeatsSelected = (payload) => {
  return {
    type: SET_SEAT_SELECTED,
    payload: payload,
  };
};

export const setReservationID = (payload) => {
  return {
    type: SET_RESERVATION_ID,
    payload: payload,
  };
};

export const resetReservationID = () => {
  return {
    type: RESET_RESERVATION_ID,
    payload: {},
  };
};

export const selectSeats = (payload) => {
  return function (dispatch) {
    dispatch(setSeatsSelected(payload));
  };
};

export const setSeatSelectError = (error) => ({
  type: SET_SEAT_SELECT_ERROR,
  payload: error,
});

export const setTransactionId = (payload) => ({
  type: SET_TRANSACTION_ID,
  payload,
});

export const setTransactionError = (error) => ({
  type: SET_TRANSACTION_ERROR,
  payload: error,
});

export const clearBookingState = () => ({
  type: CLEAR_BOOKING_STATE,
});

export const clearFoodAndBeveragesState = () => ({
  type: CLEAR_FOOD_AND_BEVERAGES_STATE,
});

const setTicketPrice = (payload) => ({
  type: SET_TICKET_PRICE,
  payload,
});

export const setGuestId = (payload) => ({
  type: SET_GUEST_ID,
  payload,
});

const startTimer = () => ({
  type: START_TIMER,
  payload: timerInterval,
});

export const resetTimer = () => ({
  type: STOP_TIMER,
});

const setBookingFeeData = (payload) => ({
  type: SET_BOOKING_FEE_DATA,
  payload,
});

const setAdditionalData = (payload) => ({
  type: SET_ADDITIONAL_DATA,
  payload,
});

export const clearSeatLayoutErrors = () => ({
  type: CLEAR_ERRORS,
});

export const clearSelectedSeats = () => ({
  type: CLEAR_SELECTED_SEATS,
});

export const clearFnbState = () => ({
  type: CLEAR_FNB_STATE,
});

export const setBookingDetails = (payload) => ({
  type: SET_BOOKING_DETAILS,
  payload,
});

export const updateSeatLayout = (payload) => async (dispatch, getState) => {
  let state = getState();

  dispatch({
    type: UPDATE_SEAT_LAYOUT,
    payload: {
      ...payload,
      globalOptions: state.global.globalOptions,
    },
  });
};

export const clearStore = () => async (dispatch) => dispatch({ type: CLEAR_STORE });

export const onSetCompleteRateCard = (payload) => async (dispatch) =>
  dispatch({ type: ON_SET_COMPLETE_RATE_CARD, payload });

export const onSetCompleteNewTotalAmount = (payload) => async (dispatch) =>
  dispatch({ type: ON_SET_COMPLETE_NEW_TOTAL_AMOUNT, payload });

const setPaymentModes = (payload) => ({
  type: SET_PAYMENT_MODES,
  payload,
});

export const stopTimer = () => {
  return function (dispatch) {
    clearInterval(timerInterval);
    dispatch(resetTimer());
  };
};

export const fetchSeatLayoutData = (payload) => async (dispatch) => {
  try {
    // payload.getAllRecords = true;
    const data = {};
    dispatch(fetchSeatLayoutLoading());
    const seats = await BookingService.GetSeatLayout(payload);

    data.seats = seats.data;
    console.log(seats.data, 'seats.data');
    const seatType = await BookingService.GetSeatType({
      screen_id: payload.screen_id,
    });
    data.seatType = seatType.data;
    if (seats && seats.status) {
      let seatData = [];
      let seatData1 = groupBy(data.seats.Records, 'sst_seat_type');
      data.seatType.Records = data.seatType.Records.sort(function (a, b) {
        return a.sst_order - b.sst_order;
      });
      data.seatType.Records.map((type) => {
        for (var key in seatData1) {
          if (seatData1.hasOwnProperty(key)) {
            if (key == type.sst_seat_type) {
              seatData[key] = seatData1[key];
            }
          }
        }
      });
      let tempArray = [];
      for (let key in seatData) {
        if (seatData.hasOwnProperty(key)) {
          let seat_price = 0;
          let seat_price_obj = seatData[key].filter((x) => {
            return x;
          });
          if (seat_price_obj) {
            seat_price = seat_price_obj[0].seat_price;
          }
          tempArray.push({
            seatType: key,
            seat_price: seat_price,
            seats: seatData[key],
          });
        }
      }
      let finalArray = [];
      for (let i = 0; i < tempArray.length; i++) {
        let singleSeatType = tempArray[i];

        let seatData_1 = groupBy(singleSeatType.seats, 'sl_row_num');
        let tempArray_1 = [];
        for (let key in seatData_1) {
          if (seatData_1.hasOwnProperty(key)) {
            let seatData_2 = seatData_1[key].map((singleSeat) => {
              singleSeat.selectStatus = false;
              singleSeat.SelectStatusFlag = false;
              return singleSeat;
            });
            seatData_2 = seatData_2.sort(function (a, b) {
              return a.sl_col_num - b.sl_col_num;
            });

            tempArray_1.push(seatData_2);
          }
        }
        tempArray[i].seatsFinal = tempArray_1;
      }

      let seatTypesNew = tempArray;
        try {
          seatTypesNew = createSeatLayout(
            data.seats.Records, // res.data.Records,
            data.seatType, // res.data.screen_seat_type
          );
          console.log('seatTypesNew', seatTypesNew);
        } catch (e) {
          console.log('E', e);
          console.log('Failed to create screen layout: ' + e.message);
        }

      dispatch(fetchSeatLayoutSuccess(seatTypesNew));
    }
  } catch (error) {
    dispatch(fetchSeatLayoutError(error.message));
  }
};

function getSubarray(array = [], fromIndex = 0, toIndex = 0) {
  let a = JSON.parse(JSON.stringify(array));
  return a.slice(fromIndex, toIndex + 1);
}

function createSeatLayout(flatSeats = [], screen_seat_type = []) {
  let seatsRowsRaw = [];

  console.log('createSeatLayout flatSeats', flatSeats);
  console.log('createSeatLayout screen_seat_type', screen_seat_type);

  /** get the grid */
  seatsRowsRaw = _(flatSeats)
    .groupBy((item) => String(item.sl_row_num).padStart(4, '0'))
    .sortBy((group) => flatSeats.indexOf(group[0]))
    .value()
    .map((row) => {
      row.forEach((singleSeat) => {
        singleSeat.selectStatus = singleSeat.SelectStatusFlag = false;
      });
      /** sorting by col asc for each row, just in case */
      return row.sort((a, b) => a.sl_col_num - b.sl_col_num);
    });

    console.log('seatsRowsRaw', seatsRowsRaw);

  let ssts = [];
  let sstsPayload = _.cloneDeep(screen_seat_type['Records']);
  let ssts_seats = [];
  let seatsRowsRawPrevIndex = 0;
  let seatsRowsRawlength = seatsRowsRaw.length;

  console.log('sstsPayload', sstsPayload);

  /** get sst seat types from the grid */
  seatsRowsRaw.forEach((row, index) => {
    let sst = sstsPayload.filter((x) => x.sst_id == row[0].sst_id);
    console.log('ssts', ssts);
    console.log('row[0]', row[0]);
    console.log('sst', sst);
    if (ssts && ssts.length > 0 && ssts[ssts.length - 1].sst_id != row[0].sst_id) {
      ssts.push(sst[0]);
    }
    if (ssts.length == 0) {
      ssts.push(sst[0]);
    }

    /** get seat slice for sst seat types of the grid */
    const rowNext = seatsRowsRaw[index + 1];
    if (rowNext && row[0].sst_id != rowNext[0].sst_id) {
      ssts_seats.push(getSubarray(seatsRowsRaw, seatsRowsRawPrevIndex, index));
      seatsRowsRawPrevIndex = index + 1;
    }
    if (rowNext == undefined) {
      ssts_seats.push(getSubarray(seatsRowsRaw, seatsRowsRawPrevIndex, index));
      seatsRowsRawPrevIndex = seatsRowsRawlength;
    }
  });

  if (ssts.length !== ssts_seats.length) {
    throw new Error(
      'something went wrong with the seats array, ssts, ssts_seats doesnt match',
    );
  }

  /** zip seat slice into sst seat types of the grid */
  return _.zipWith(ssts, ssts_seats, function (types, seats) {
    return {
      ...types,
      seatType: types.sst_seat_type,
      seat_net_price: seats.flatMap((x) => x)[0].seat_net_price || '',
      seat_price: seats.flatMap((x) => x)[0].seat_price || '',
      seats: seats.flatMap((x) => x),
      seatsFinal: seats.map((x) => x),
      debug: seats
        .flatMap((x) => x)
        .map((x) => x.sl_seat_name)
        .join(','),
    };
  });
}

export const clearCardInfo = () =>
  async (dispatch, getState) => {
    dispatch({
      type: CLEAR_CARD_INFO
    });
  };

export const validateBankOfferAction =
  ({ offer_id, callback = () => {}, cardInfo}) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: SET_CARD_INFO,
        payload: {
          ...cardInfo,
          is_valid: false,
          bank_validate_msg: '',
          is_loading: false
        },
      });
      console.log("COMING HERE validateBankOfferAction", cardInfo)
      let intentPayload = {
        offer_id: offer_id,
        bank_card_number: cardInfo?.bank_card_number,
        bank_card_number_with_space: cardInfo?.bank_card_number_with_space,
      };
      const { data } = await BookingService.ValidateBankCard(intentPayload);
      if (data.status) {
        dispatch({
          type: SET_CARD_INFO,
          payload: {
            ...cardInfo,
            is_valid: true,
            bank: data?.bank ?? null,
            bank_validate_msg: data?.valid_message,
            is_loading: false
          },
        });
        callback(false)
      } else {
        dispatch({
          type: SET_CARD_INFO,
          payload: {
            ...cardInfo,
            is_valid: false,
            bank_validate_msg: data?.valid_message,
            is_loading: false
          },
        });
        callback(false)
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      callback && callback(false)
    }
  };

export const fetchReservationDetailsBrij =
  ({ reservation_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_RESERVATION_DETAILS_V2 });
      const { data } = await MovieService.GetReservationDetailsBrij({
        reservation_id,
      });
      if (data && data.status) {
        dispatch({
          type: FETCH_RESERVATION_DETAILS_V2_SUCCESS,
          payload: data.Records[0],
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_RESERVATION_DETAILS_V2_ERROR, error });
    }
  };

export const fetchSeatTypes = (payload) => async (dispatch) => {
  try {
    const { screen_id } = payload;
    const fetchSeatTypesPayload = {
      table: `screen_seat_type`,
      sst_is_active: `Y`,
      screen_id,
    };
    dispatch({ type: FETCH_SEAT_TYPE_LOADING });
    const { data } = await BookingService.GetSeatTypes(fetchSeatTypesPayload);
    if (data && data.status) {
      dispatch({ type: FETCH_SEAT_TYPE_SUCCESS, payload: data.Records });
    }
  } catch (error) {
    dispatch({ type: FETCH_SEAT_TYPE_ERROR });
  }
};
export const setSeatsString = (payload) => async (dispatch) => {
  const { seats, session_id } = payload;
  dispatch({ type: SET_SEATS_STRING, payload: seats });
  dispatch({ type: SET_SESSION_ID, payload: session_id });
};

export const clearTimer = () => {
  if (timerInterval) {
    clearInterval(timerInterval);
  }
};

export const reserveSeats =
  ({
    selected_seats,
    schedule_show_id,
    history,
    cinema_id,
    seatGroup,
    cinema,
    callback,
    session_id,
    movie_id,
    screen_id,
    callback2,
    callback3,
    mode,
  }) =>
  async (dispatch, getState) => {
    try {
      console.log('getState() :>> ', getState());
      const {
        booking: { reservation_id },
      } = getState();

      let seats = selected_seats.map((x) => x.sl_id);
      let SeatTypeId = selected_seats.map((x) => x.sst_id);
      let isVipOrBlockSeats =
        selected_seats.filter((x) => {
          return x.seat_reserve_type_id == 5 || x.seat_reserve_type_id == 12;
        }).length > 0;
      let newSeatLayoutArray = [];
      let rateCardGroup = [];
      seatGroup.map((seat_g) => {
        seat_g.rate_card_group.map((rg) => {
          let findIndex32 = rateCardGroup.findIndex((newrg) => {
            return newrg.tt_id == rg.tt_id;
          });

          if (findIndex32 >= 0) {
            rateCardGroup[findIndex32]['count'] =
              rateCardGroup[findIndex32]['count'] + rg.totalSeatSelected;
          } else {
            let objRateCard = {
              tt_id: rg.tt_id,
              tt_name: rg.tt_name.trim(),
              count: rg.totalSeatSelected,
            };
            rateCardGroup.push(objRateCard);
          }

          for (let i = 0; i < rg.totalSeatSelected; i++) {
            let obj = {
              rate_card_id: rg.rate_card_id,
              tt_id: rg.tt_id,
              rc_g_id: rg.rc_g_id,
              rcg_c_id: rg.rcg_c_id,
              tt_name: rg.tt_name,
              seat_type_id: seat_g.sst_id,
              status: false,
            };

            newSeatLayoutArray.push(obj);
          }
        });
      });
      // const reservation_id = Math.floor(100000000 + Math.random() * 900000000);
      const { data } = await BookingService.ReserveSeats({
        groupRateCard: true,
        seat_layout_id: seats,
        seat_type_id: SeatTypeId,
        newSeatLayout: newSeatLayoutArray,
        schedule_show_id,
        reservation_id,
        isVipOrBlockSeats,
        aggregator_cinema: cinema_id,
        is_kiosk: true,
      });
      if (data && data.status) {
        dispatch(setReservationID(reservation_id));

        if (
          cinema.cine_seat_release_time_in_mins !== undefined &&
          cinema.cine_seat_release_time_in_mins !== null
        ) {
          dispatch({
            type: SET_CHECKOUT_TIMER,
            payload: cinema.cine_seat_release_time_in_mins,
          });
        }
        // dispatch(setBookingFeeData(data.data.booking_fee));
        clearTimer();
        timerInterval = setInterval(() => {
          dispatch(startTimer());
        }, 1000);
        if (mode === 1) {
          history.push(`/checkout-one/${reservation_id}`);
        } else {
          history.push(`/fnb/${cinema_id}`);
        }
      } else {
        toast.error(data.message);
        dispatch(clearSelectedSeats());
        dispatch(
          fetchSeatLayoutData({
            ss_id: session_id,
            md_id: movie_id,
            screen_id,
          }),
        );
        callback2();
        callback3([]);
      }
    } catch (error) {
      if (error.response) {
        dispatch(setSeatSelectError(error.response.data));
      } else {
        dispatch(setSeatSelectError({ message: error.message }));
      }
    } finally {
      callback(false);
    }
  };

export const reserveSeatsOnDemand =
  ({
    mycinema_ss_d_id,
    booking_type_id,
    selected_seats,
    schedule_show_id,
    history,
    cinema_id,
    seatGroup,
    cinema,
    callback,
    session_id,
    movie_id,
    screen_id,
    callback2,
    callback3,
    mode,
  }) =>
  async (dispatch, getState) => {
    try {
      console.log('getState() :>> ', getState());
      const {
        // booking: { reservation_id },
      } = getState();

      let seats = selected_seats.map((x) => x.sl_id);
      let SeatTypeId = selected_seats.map((x) => x.sst_id);
      let isVipOrBlockSeats =
        selected_seats.filter((x) => {
          return x.seat_reserve_type_id == 5 || x.seat_reserve_type_id == 12;
        }).length > 0;
      let newSeatLayoutArray = [];
      let rateCardGroup = [];
      seatGroup.map((seat_g) => {
        seat_g.rate_card_group.map((rg) => {
          let findIndex32 = rateCardGroup.findIndex((newrg) => {
            return newrg.tt_id == rg.tt_id;
          });

          if (findIndex32 >= 0) {
            rateCardGroup[findIndex32]['count'] =
              rateCardGroup[findIndex32]['count'] + rg.totalSeatSelected;
          } else {
            let objRateCard = {
              tt_id: rg.tt_id,
              tt_name: rg.tt_name.trim(),
              count: rg.totalSeatSelected,
            };
            rateCardGroup.push(objRateCard);
          }

          for (let i = 0; i < rg.totalSeatSelected; i++) {
            let obj = {
              rate_card_id: rg.rate_card_id,
              tt_id: rg.tt_id,
              rc_g_id: rg.rc_g_id,
              rcg_c_id: rg.rcg_c_id,
              tt_name: rg.tt_name,
              seat_type_id: seat_g.sst_id,
              status: false,
            };

            newSeatLayoutArray.push(obj);
          }
        });
      });
      const reservation_id = Math.floor(100000000 + Math.random() * 900000000);
      const { data } = await BookingService.CinemaReserveSeats({
        mycinema_ss_d_id,
        mycinema_booking_type_id: booking_type_id,
        booking_type_id,
        groupRateCard: true,
        seat_layout_id: seats,
        seat_type_id: SeatTypeId,
        newSeatLayout: newSeatLayoutArray,
        schedule_show_id,
        cinema_reservation_id: reservation_id,
        isVipOrBlockSeats,
        aggregator_cinema: cinema_id,
        cinema_id,
      });
      if (data && data.status) {
        dispatch(setReservationID(reservation_id));

        if (
          cinema.cine_seat_release_time_in_mins !== undefined &&
          cinema.cine_seat_release_time_in_mins !== null
        ) {
          dispatch({
            type: SET_CHECKOUT_TIMER,
            payload: cinema.cine_seat_release_time_in_mins,
          });
        }
        // dispatch(setBookingFeeData(data.data.booking_fee));
        clearTimer();
        timerInterval = setInterval(() => {
          dispatch(startTimer());
        }, 1000);
        if (mode === 1) {
          // skipping it for now
          // history.push(`/checkout-one/${reservation_id}`);
        } else {
          // skipping fnb for now
          // history.push(`/fnb/${cinema_id}`);
        }
        dispatch(fetchReservationDetails({ reservation_id })).then((_) => {
          history.push(`/checkout-on-demand/${reservation_id}`);
        });
      } else {
        toast.error(data.message);
        dispatch(clearSelectedSeats());
        dispatch(
          fetchSeatLayoutData({
            ss_id: session_id,
            md_id: movie_id,
            screen_id,
          }),
        );
        callback2();
        callback3([]);
      }
    } catch (error) {
      if (error.response) {
        dispatch(setSeatSelectError(error.response.data));
      } else {
        dispatch(setSeatSelectError({ message: error.message }));
      }
    } finally {
      callback(false);
    }
  };

export const cancelTransaction = (payload) => {
  return function (dispatch) {
    BookingService.CancelTransaction(payload)
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          console.log('data', data);
        }
      })
      .catch((err) => {
        console.log('Cancel Transaction Error', err.message);
      });
  };
};

export const performCheckout = (payload) => {
  return async function (dispatch) {
    try {
      const response = await BookingService.BookSeats(payload);

      if (response && response.status === 200) {
        const { data } = response;
        console.log('booking response', data);
        dispatch(setTransactionId(data.data));
        dispatch(setBookingDetails(data));
        dispatch(clearBookingState());
        dispatch(clearFoodAndBeveragesState());
      }
    } catch (err) {
      if (err.response) {
        dispatch(setTransactionError(err.response.data.message));
      } else {
        dispatch(setTransactionError(err.message));
      }
    }
  };
};

export const fetchShowDetails = (payload) => async (dispatch) => {
  try {
    const { data } = await BookingService.GetBookingDetails(payload);
    if (data && data.status) {
      console.log('data :>> ', data);
      if (data.Records.length > 0) {
        dispatch(fetchShowDetailsSuccess(data.Records[0]));
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchTicketPrice = (payload) => {
  return function (dispatch) {
    BookingService.GetTicketPrice(payload)
      .then((response) => {
        if (response && response.data.data) {
          dispatch(setTicketPrice(response.data.data));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const reserveFnbItems = (payload) => {
  return async function (dispatch) {
    try {
      const response = await BookingService.SelectFnbItems(payload);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
};

export const onSelectFnb = (type, item) => {
  return {
    type: SELECT_FNB_ITEM,
    payload: { type, item },
  };
};

export const bookFnb =
  ({
    reservation_id,
    selectedFnbItems,
    history,
    aggregator_cinema,
    currency_id,
    ft_total_amount,
    ft_net_amount,
    email,
    mobile,
    paymentMode,
    currency_code,
    country_mob_code,
    callback,
    mode,
    successfulVoucherCode,
    fnb_delivery_time,
    customer_details,
  }) =>
  async (dispatch) => {
    try {
      selectedFnbItems.map((item) => (item.quantity = item.Quantity));
      const { data } = await FnbService.BookFnb({
        isGroupRateCard: true,
        reservation_id,
        fnbItemList: selectedFnbItems,
        payment_mode_id: paymentMode.pm_id,
        cinema_id: aggregator_cinema.cinema_id,
        currency_id,
        ft_total_amount,
        ft_net_amount,
        ft_order_status: 3,
        aggregator_cinema,
        offer: { t_offer_price: 0 },
        payment_mode: paymentMode,
        booking_source_id: 2,
        fst_booking_source: 'Online',
        fnb_delivery_time,
        customer_details,
      });
      if (data && data.status && data.Records) {
        console.log('data :>> ', data);
        dispatch(setReservationID(reservation_id));
        dispatch({ type: SET_FT_ID, payload: data.Records.ft_id });
        if (mode === 2) {
          data.Records.paymentMode = paymentMode;
          history.push(`/final-ticket`);
          dispatch({ type: SET_FNB_BOOKING_DETAILS, payload: data.Records });
        } else {
          dispatch(
            bookSeats({
              items: selectedFnbItems,
              reservation_id,
              history,
              aggregator_cinema: aggregator_cinema.cinema_id,
              ft_id: data.Records.ft_id,
              email,
              mobile,
              paymentMode,
              currency_code,
              country_mob_code,
              successfulVoucherCode,
              callback,
            }),
          );
        }
        // dispatch(setBookingFeeData(data.data.booking_fee));
        clearTimer();
        timerInterval = setInterval(() => {
          dispatch(startTimer());
        }, 1000);
      }
    } catch (error) {
      if (error.response) {
        dispatch(setSeatSelectError(error.response.data));
      } else {
        dispatch(setSeatSelectError({ message: error.message }));
      }
    } finally {
      callback(false);
    }
  };

export const bookSeats =
  ({
    aggregator_cinema,
    items,
    reservation_id,
    history,
    ft_id,
    email,
    mobile,
    paymentMode,
    currency_code,
    country_mob_code,
    callback,
  }) =>
  async (dispatch, getState) => {
    console.log('getState() :>> ', getState());
    const {
      booking: { successfulVoucherCode },
    } = getState();
    console.log('successfulVoucherCode :>> ', successfulVoucherCode);
    console.log('ft_id', ft_id);
    try {
      const { data } = await BookingService.BookSeats({
        reservation_id,
        payment_mode_id: paymentMode.pm_id,
        aggregator_cinema,
        payment_mode: paymentMode,
        ft_id,
        email,
        mobile_number: mobile,
        country_mob_code,
        isGroupRateCard: true,
        currency_code,
        offerFor: successfulVoucherCode ? 'voucher' : null,
        offer: successfulVoucherCode
          ? successfulVoucherCode
          : { is_offer_applied: false, t_offer_price: 0 },
      });
      if (data && data.status) {
        dispatch({ type: SET_BOOKING_ID, payload: data.bookingID });
        dispatch({ type: SET_BOOKING_CODE, payload: data.bookingCode });
        dispatch(setBookingDetails(data));
        history.push(`/final-ticket`);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      callback(false);
    }
  };

export const bookSeatsOnDemand =
  ({
    aggregator_cinema,
    items,
    reservation_id,
    history,
    ft_id,
    email,
    mobile,
    paymentMode,
    currency_code,
    country_mob_code,
    callback,
  }) =>
  async (dispatch, getState) => {
    console.log('getState() :>> ', getState());
    const {
      booking: { successfulVoucherCode },
    } = getState();
    console.log('successfulVoucherCode :>> ', successfulVoucherCode);
    console.log('ft_id', ft_id);
    try {
      const { data } = await BookingService.CinemaBookSeats({
        reservation_id,
        payment_mode_id: paymentMode.pm_id,
        cinema_id: aggregator_cinema,
        payment_mode: paymentMode,
        ft_id,
        email,
        mobile_number: mobile,
        country_mob_code,
        isGroupRateCard: true,
        currency_code,
        offerFor: successfulVoucherCode ? 'voucher' : null,
        offer: successfulVoucherCode
          ? successfulVoucherCode
          : { is_offer_applied: false, t_offer_price: 0 },
        unpaid_booking: false,
        order_code: null,
        approverId: null,
        isLoyaltyApplied: false,
        isSplitPayment: false,
        MyCinema: true,
      });
      if (data && data.status) {
        dispatch({ type: SET_BOOKING_ID, payload: data.bookingID });
        dispatch({ type: SET_BOOKING_CODE, payload: data.sb_booking_code });
        dispatch(setBookingDetails(data));
        // dispatch(fetchReservationDetails({reservation_id})).then(_ => {
        history.push(`/final-ticket`);
        // });
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      callback(false);
    }
  };

export const getPrintTicket =
  ({ booking_id, cinema_id }, payload) =>
  async (dispatch) => {
    try {
      // const { data } = await BookingService.PrintTicket({
      //   booking_id,
      //   cinema_id,
      // });
      const { data } = await BookingService.PrintTicket2(payload);
      // const original = document.body.innerHTML;
      // document.body.innerHTML = data;
      // window.print();
      // document.body.innerHTML = original;
      return data;
    } catch (error) {
      console.log('error', error);
    }
  };

export const verifiyTicketNumber =
  (booking_code, history, cinema_id) => async (dispatch) => {
    try {
      const { data } = await BookingService.PickupTicket({
        booking_code,
        cinema_id: cinema_id,
      });
      console.log('data', data);
      if (data && data.status && data.Records && data.Records.result.length > 0) {
        dispatch({ type: PICKUP_TICKET_SUCCESS, payload: data.Records });
        dispatch({
          type: PICKUP_TICKET_CONTENT_SUCCESS,
          payload: data.MovieContentArtworks,
        });
        history.push(`/kiosk-collect-2`);
      } else {
        dispatch({ type: PICKUP_TICKET_ERROR });
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

export const ResetPickupTicketError = () => async (dispatch) => {
  dispatch({ type: RESET_PICKUP_TICKET_ERROR });
};

export const fetchPaymentModes = () => async (dispatch) => {
  try {
    const { data } = await BookingService.GetValidPaymentModes();
    if (data && data.status && data.Records) {
      let filter = data.Records.filter((pm) => {
        return [
          18, // CC AVENUE
          24, // Khalti Wallet (Preferred Payment Partner)
          // 25, // HBL
          28, // eSewa Wallet
          36, // IME Pay
          22, // Al Rajhi Bank
          43, // Al Rajhi Bank
          44, // Al Rajhi Bank
          45, // Al Rajhi Bank
        ].includes(pm.pm_id);
      });

      filter = filter.map((_pm) => {
        if (_pm['pm_id'] == 43) {
          let tempObj = {
            card_image: masterCardLogo,
            name_on_btn: 'Credit Card',
            sub_name_2: '',
            img_height: null,
            img_width: null,
            order: 1,
            bank: null,
          };
          _pm = { ..._pm, ...tempObj };
        } else if (_pm['pm_id'] == 44) {
          let tempObj = {
            card_image: masterCardStandardCharteredLogo,
            name_on_btn: 'SCB CREDIT CARD',
            sub_name_2: 'Standard Chartered Bank 50% Discount offer',
            img_height: 40,
            img_width: 30,
            order: 3,
            bank: 'SCB BANK',
          };
          _pm = { ..._pm, ...tempObj };
        } else if (_pm['pm_id'] == 45) {
          let tempObj = {
            card_image: benefitLogo,
            name_on_btn: 'Debit Card',
            sub_name_2: '',
            img_height: null,
            img_width: null,
            order: 2,
            bank: null,
          };
          _pm = { ..._pm, ...tempObj };
        } else {
          let tempObj = {
            card_image: null,
            name_on_btn: _pm.pm_payment_mode,
            sub_name_2: '',
            img_height: null,
            img_width: null,
            order: 4,
            bank: null,
          };
          _pm = { ..._pm, ...tempObj };
        }
        return _pm;
      });

      dispatch(setPaymentModes(filter));
    } else {
      dispatch(setPaymentModes([]));
    }
  } catch (err) {
    dispatch(setPaymentModes([]));
    console.error(err);
  }
};

/* Hit Electron for printing the ticket */
export const printTicket =
  ({ cinema_id, showBookingId, baseurl }) =>
  async (dispatch) => {
    // BookingService.PrintTicketElectron(payload)
    //   .then(() => {
    //     console.log("print successful.");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    if (electronSocket && electronSocket.connected) {
      electronSocket.emit('on_print_ticket', {
        cinema_id,
        showBookingId,
        baseurl,
      });
    } else {
      toast.error('Not connected to POS Socket');
    }
  };

/* Hit Electron for printing the ticket */
export const printTicket2 = (payload) => async (dispatch) => {
  if (electronSocket && electronSocket.connected) {
    electronSocket.emit('on_print_ticket', payload);
  } else {
    toast.error('Not connected to Printer OR Printer not detected');
  }
};

export const startTimer2 = () => async (dispatch) => {
  clearTimer();
  timerInterval = setInterval(() => {
    dispatch(startTimer());
  }, 1000);
};

export const clearSelectedFnbItems = () => async (dispatch) =>
  dispatch({ type: CLEAR_SELECTED_FNB_ITEMS });

export const fetchVoucherList =
  ({
    seat: { sst_id },
    cinema_id,
    movie_details_id,
    show_date_time,
    aggregator_cinema_id,
    selectSeats,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_VOUCHERS });
      const { data } = await BookingService.GetVoucherList({
        seat_type_id: sst_id,
        cinema_id,
        movie_details_id,
        show_date_time,
        aggregator_cinema_id,
        selectSeats,
      });
      if (data && data.status && data.Records && data.Records.length > 0) {
        dispatch({ type: FETCH_VOUCHERS_SUCCESS, payload: data.Records });
      } else {
        // toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

export const verifyVoucherCode =
  ({ voucherCode, vouchers, selectedTicketPrice, callback, callback2 }) =>
  async (dispatch) => {
    try {
      dispatch({ type: VERIFY_VOUCHER });
      const voucher = vouchers.find(
        (voucher) => voucher.voucher_code === voucherCode,
      );
      if (!voucher) {
        toast.error(`Invalid voucher code.`);
        return;
      }
      const { data } = await BookingService.ValidateVoucherCode({
        vouchercode: voucherCode,
        voucher_title: voucher.voucher_title,
      });
      if (data && data.status) {
        const calculateVoucherPrice = await CALCULATE_OFFER_PRICE({
          offer: voucher,
          offerFor: 'voucher',
          selectedTicketPrice,
        });
        dispatch({
          type: VERIFY_VOUCHER_SUCCESS,
          payload: {
            offer: calculateVoucherPrice.offer,
            voucher: calculateVoucherPrice.voucher,
          },
        });
        // callback();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      callback2();
    }
  };

const CALCULATE_OFFER_PRICE = ({ offer, offerFor, selectedTicketPrice }) =>
  new Promise((resolve, _) => {
    if (offerFor === 'voucher') {
      let voucherObj = offer;
      let voucherDiscount = 0;
      let voucher = {};
      if (voucherObj.voucher_is_percentage === 'Y') {
        voucherDiscount =
          voucherObj.voucher_redeem_on_gross === 'Y'
            ? (voucherObj.voucher_amount / 100) * selectedTicketPrice.priceInCents
            : voucherObj.voucher_redeem_on_net === 'Y'
            ? (voucherObj.voucher_amount / 100) *
              (selectedTicketPrice.priceInCents - selectedTicketPrice.taxInCents)
            : 0;
      } else {
        voucherDiscount = voucherObj.voucher_amount;
      }

      if (voucherDiscount > voucherObj.voucher_max_redemption_amount) {
        voucherDiscount = voucherObj.voucher_max_redemption_amount;
      } else if (voucherDiscount < voucherObj.voucher_min_redemption_amount) {
        voucherDiscount = voucherObj.voucher_min_redemption_amount;
      }
      voucher.offerPrice = voucherDiscount;
      voucher.priceInCents = selectedTicketPrice.priceInCents - voucherDiscount;
      // state.offerSet = true;

      // state.selectedOffers.t_offer_price = state.offerPrice;
      // state.selectedOffers.oc_discount_value = state.offerPrice;
      resolve({ offer, voucher });
    }
  });

export const releaseRealtimeReservedSeats = () => async (dispatch, getState) => {
  let state = getState();

  let reservation_id = state.booking.reservation_id;
  let guest_user_id = window.localStorage.getItem('guest_user_id');

  if (!reservation_id || !guest_user_id) {
    return;
  }

  try {
    let response = await BookingService.ReleaseRealtimeReservedSeats({
      reservation_id,
      guest_user_id,
    });

    if (response?.data?.status) {
      dispatch(setReservationID(null));
    }
  } catch (err) {
    console.log(err);
  }
};

export const makeCCAvenuePayment =
  ({ reservation_id, guest_id, callback = () => {}, appliedCashCard = {} }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();

      if (!guest_id) {
        toast.error('Something Went Wrong');
        return;
      }

      // call reset timer api
      let intentPayload = {
        reservation_id,
        cust_id: 0,
        guest_id,
      };

      const { data } = await BookingService.CreateCCAvenueRedirect(intentPayload);
      const query = createQueryString(intentPayload);
      console.log({ data, query });

      return data;
    } catch (error) {
      toast.error('Something Went Wrong');
      console.error('makeCCAvenuePayment', error);
    } finally {
      callback && callback(false);
    }
  };

export const makeMasterCardAsfPayment = ({ reservation_id, guest_id, callback = () => {}, appliedCashCard = {} }) =>
async (dispatch, getState) => {
  try {
    const state = getState();

    let redirect_origin =
        state?.global?.globalOptions?.find((g) => g.go_key === 'WEBSITE_URL')
          ?.go_value || `https://epixcinemas.brij.tech`;

    if (!guest_id) {
      toast.error('Something Went Wrong');
      return;
    }

    let intentPayload = {
      reservation_id,
      cust_id: 0,
      guest_id,
      redirect_origin: redirect_origin ? redirect_origin : undefined,
    };

    const { data } = await BookingService.CreateMasterCardAsfIntent(intentPayload);
    const query = createQueryString(intentPayload);
    console.log({ data, query });

    return data;
  } catch (error) {
    toast.error('Something Went Wrong');
    console.error('makeCCAvenuePayment', error);
  } finally {
    callback && callback(false);
  }
};

export const makeMasterCardScPayment = ({ reservation_id, guest_id, callback = () => {}, appliedCashCard = {} }) =>
async (dispatch, getState) => {
  try {
    const state = getState();

    let redirect_origin =
        state?.global?.globalOptions?.find((g) => g.go_key === 'WEBSITE_URL')
          ?.go_value || `https://epixcinemas.brij.tech`;

    if (!guest_id) {
      toast.error('Something Went Wrong');
      return;
    }

    let intentPayload = {
      reservation_id,
      cust_id: 0,
      guest_id,
      redirect_origin: redirect_origin ? redirect_origin : undefined,
    };

    const { data } = await BookingService.CreateMasterCardScIntent(intentPayload);
    const query = createQueryString(intentPayload);
    console.log({ data, query });

    return data;
  } catch (error) {
    toast.error('Something Went Wrong');
    console.error('makeCCAvenuePayment', error);
  } finally {
    callback && callback(false);
  }
};

export const makeBenefitCardPayment = ({ reservation_id, guest_id, callback = () => {}, appliedCashCard = {} }) =>
async (dispatch, getState) => {
  try {
    const state = getState();

    let redirect_origin =
    state?.global?.globalOptions?.find((g) => g.go_key === 'WEBSITE_URL')
      ?.go_value || `https://epixcinemas.brij.tech`;

    if (!guest_id) {
      toast.error('Something Went Wrong');
      return;
    }

    let intentPayload = {
      reservation_id,
      cust_id: 0,
      guest_id,
      redirect_origin: redirect_origin ? redirect_origin : undefined,
    };

    const { data } = await BookingService.CreateBenefitCardIntent(intentPayload);
    const query = createQueryString(intentPayload);
    console.log({ data, query });

    return data;
  } catch (error) {
    toast.error('Something Went Wrong');
    console.error('makeCCAvenuePayment', error);
  } finally {
    callback && callback(false);
  }
};

export const makeEsevaPayment =
  ({ reservation_id, guest_id, callback = () => {}, appliedCashCard = {} }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();

      let redirect_origin =
        state?.global?.globalOptions?.find((g) => g.go_key === 'WEBSITE_URL')
          ?.go_value || '';

      if (!guest_id) {
        toast.error('Something Went Wrong');
        return;
      }

      // call reset timer api
      let intentPayload = {
        reservation_id,
        cust_id: 0,
        guest_id,
        redirect_origin: redirect_origin ? redirect_origin : undefined,
      };

      const { data } = await BookingService.CreateEsevaRedirect(intentPayload);
      const query = createQueryString(intentPayload);
      console.log({ data, query });

      return data;
    } catch (error) {
      toast.error('Something Went Wrong');
      console.error('makeEsevaPayment', error);
    } finally {
      callback && callback(false);
    }
  };

export const makeIMEPayment =
  ({ reservation_id, guest_id, callback = () => {}, appliedCashCard = {} }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();

      let redirect_origin =
        state?.global?.globalOptions?.find((g) => g.go_key === 'WEBSITE_URL')
          ?.go_value || '';

      if (!guest_id) {
        toast.error('Something Went Wrong');
        return;
      }

      // call reset timer api
      let intentPayload = {
        reservation_id,
        cust_id: 0,
        guest_id,
        redirect_origin: redirect_origin ? redirect_origin : undefined,
      };

      const { data } = await BookingService.CreateIMEPayRedirect(intentPayload);
      const query = createQueryString(intentPayload);
      console.log({ data, query });

      return data;
    } catch (error) {
      toast.error('Something Went Wrong');
      console.error('makeEsevaPayment', error);
    } finally {
      callback && callback(false);
    }
  };

export const makeKhaltiPayment =
  ({ reservation_id, guest_id, callback = () => {}, appliedCashCard = {} }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();

      let redirect_origin =
        state?.global?.globalOptions?.find((g) => g.go_key === 'WEBSITE_URL')
          ?.go_value || '';

      if (!guest_id) {
        toast.error('Something Went Wrong');
        return;
      }

      // call reset timer api
      let intentPayload = {
        reservation_id,
        cust_id: 0,
        guest_id,
        redirect_origin: redirect_origin ? redirect_origin : undefined,
      };

      const { data } = await BookingService.CreateKhaltiRedirect(intentPayload);
      const query = createQueryString(intentPayload);
      console.log({ data, query });

      return data;
    } catch (error) {
      toast.error('Something Went Wrong');
      console.error('makeEsevaPayment', error);
    } finally {
      callback && callback(false);
    }
  };

export const makeAlrajhiPayment =
  ({ reservation_id, guest_id, callback = () => {}, appliedCashCard = {} }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();

      let redirect_origin =
        state?.global?.globalOptions?.find((g) => g.go_key === 'WEBSITE_URL')
          ?.go_value || '';

      if (!guest_id) {
        toast.error('Something Went Wrong');
        return;
      }

      // call reset timer api
      let intentPayload = {
        reservation_id,
        cust_id: 0,
        guest_id,
        redirect_origin: redirect_origin ? redirect_origin : undefined,
      };

      const { data } = await BookingService.CreateAlrajhiRedirect(intentPayload);
      const query = createQueryString(intentPayload);
      console.log({ data, query });

      return data;
    } catch (error) {
      toast.error('Something Went Wrong');
      console.error('makeAlrajhiPayment', error);
    } finally {
      callback && callback(false);
    }
  };
